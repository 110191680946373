<template>
  <v-slide-group v-model="model" :mandatory="lock" show-arrows>
    <v-slide-item
      v-for="(item, index) in routes"
      :key="index"
      v-slot="{ active, toggle }"
    >
      <router-link
        class="decoration-none"
        v-slot="{ href }"
        :to="item.path"
        custom
      >
        <v-chip
          class="mr-3 text-button transparent"
          active-class="white--text primary"
          :input-value="active"
          :href="href"
          @click="toggle"
        >
          {{ item.label }}
        </v-chip>
      </router-link>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
export default {
  data() {
    return {
      lock: true,
      model: 0,
    };
  },

  props: {
    routes: Array,
  },

  watch: {
    $route() {
      this.handleRoute();
    },
  },

  beforeMount() {
    this.handleRoute();
  },

  methods: {
    handleRoute() {
      const index = this.routes.map((e) => e.path).indexOf(this.$route.path);

      if (index > -1) this.model = index;
      else {
        this.lock = false;
        this.model = -1;
      }
    },
  },
};
</script>

<style>
</style>