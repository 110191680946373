<template>
  <v-row
    v-if="linear"
    class="fill-height py-4"
    align-content="center"
    justify="center"
  >
    <v-col class="text-subtitle-1 text-center pb-0" cols="12">
      Carregando...
    </v-col>

    <v-col cols="8" sm="6" md="4" lg="3" xl="2">
      <v-progress-linear color="primary" indeterminate rounded height="6" />
    </v-col>
  </v-row>

  <v-row v-else class="py-4 align-center justify-center" no-gutters>
    <v-progress-circular :size="48" color="primary" indeterminate />
  </v-row>
</template>

<script>
export default {
  props: {
    linear: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>