<template>
  <v-app>
    <v-main class="background">
      <loader-page v-if="loading" />

      <Layout v-else-if="$store.getters.authenticated">
        <router-view />
      </Layout>

      <router-view v-else />
    </v-main>

    <AlertBar />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { loadSession } from "@/services/auth";
import { api } from "@/services";
import Layout from "@/layout/Layout";
import AlertBar from "@/components/AlertBar";
import socket from "@/services/socket";

export default {
  components: {
    Layout,
    AlertBar,
  },

  data() {
    return {
      loading: true,
    };
  },

  created() {
    this.startApp();
  },

  beforeMount() {
    this.startTheme();
  },

  beforeDestroy() {
    socket.disconnect();
  },

  methods: {
    ...mapActions(["setSignIn", "setSignOut"]),

    async startApp() {
      // checks if the token is still valid for a session
      const token = localStorage.getItem("token");

      try {
        if (token) {
          api.defaults.headers.common["Authorization"] = token;

          await loadSession().then((res) => {
            this.setSignIn({
              token: res.body.token,
              user: res.body.user,
            });
          });
        }
      } catch (err) {
        this.setSignOut();

        this.$router.push({ path: "/auth" });
      } finally {
        setInterval(() => {
          this.loading = false;
        }, 1000);
      }
    },

    startTheme() {
      const dark = localStorage.getItem("dark");
      if (dark) this.$vuetify.theme.dark = dark == "true" || false;
    },
  },
};
</script>

<style lang="scss"></style>
