import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    name: "Auth",
    meta: { preventAuth: true },
    component: () => import("@/views/auth/Auth"),
  },
  {
    path: "/deposits/:method?",
    name: "Deposits",
    meta: { requiredAuth: true },
    component: () => import("@/views/deposits/Deposits"),
  },
  {
    path: "/withdrawals",
    name: "Withdrawals",
    meta: { requiredAuth: true },
    component: () => import("@/views/withdrawals/Withdrawals"),
  },
  {
    path: "/customers",
    name: "Customers",
    meta: { requiredAuth: true },
    component: () => import("@/views/customers/Customers"),
  },
  {
    path: "/users",
    name: "Users",
    meta: { requiredAuth: true },
    component: () => import("@/views/users/Users"),
  },
  {
    path: "/logs/:method?",
    name: "Logs",
    meta: { requiredAuth: true },
    component: () => import("@/views/logs/Logs"),
  },
  {
    path: "*",
    redirect: "/deposits/pix",
  },
];

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
