<template>
  <v-layout fill-height column>
    <v-app-bar color="background" flat dense app>
      <v-app-bar-nav-icon
        class="rounded-lg ml-1"
        small
        @click.stop="drawer = !drawer"
      />

      <v-app-bar-title class="font-weight-bold">
        Logus Payments
      </v-app-bar-title>

      <v-spacer />

      <v-btn class="rounded-lg mr-2" small icon @click="changeTheme()">
        <v-icon v-if="$vuetify.theme.dark">mdi-moon-waning-crescent</v-icon>
        <v-icon v-else> mdi-white-balance-sunny </v-icon>
      </v-btn>
    </v-app-bar>

    <!-- drawer -->
    <v-navigation-drawer
      v-model="drawer"
      color="background"
      floating
      :mini-variant="$vuetify.breakpoint.lgAndUp"
      app
    >
      <template v-slot:prepend>
        <v-row class="align-center justify-center pt-2" no-gutters>
          <v-img
            :src="require('@/assets/logo.png')"
            :max-width="$vuetify.breakpoint.lgAndUp ? '42' : '54'"
            contain
          />

          <span
            v-if="$vuetify.breakpoint.mdAndDown"
            class="text-h6 font-weight-bold ml-3"
          >
            Logus Payments
          </span>
        </v-row>
      </template>

      <!-- routes -->
      <v-container
        class="pa-0"
        :fill-height="$vuetify.breakpoint.lgAndUp"
        fluid
      >
        <v-list class="py-0" width="100%" dense nav>
          <v-tooltip
            v-for="(route, index) in routes"
            color="grey darken-4"
            :key="index"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                :to="route.path"
                :disabled="route.disabled"
                class="my-4"
              >
                <v-list-item-icon>
                  <v-icon
                    :color="route.disabled ? 'secondary_text' : 'primary_text'"
                  >
                    {{ route.icon }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title v-text="route.label" />
              </v-list-item>
            </template>

            <span>{{ route.label }}</span>
          </v-tooltip>
        </v-list>
      </v-container>

      <template v-slot:append>
        <v-list width="100%" dense nav>
          <v-list-item @click="exit()">
            <v-list-item-icon>
              <v-icon>mdi-login-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-title> Sair </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-sheet class="radius foreground" height="100%">
      <slot />
    </v-sheet>
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      drawer: true,
      routes: [
        {
          label: "Depósitos",
          path: "/deposits/pix",
          icon: "mdi-arrow-down-thick",
          disabled: false,
        },
        {
          label: "Saques",
          path: "/withdrawals",
          icon: "mdi-arrow-up-thick",
          disabled: false,
        },
        {
          label: "Clientes",
          path: "/customers",
          icon: "mdi-account-group",
          disabled: false,
        },
        {
          label: "Usuários",
          path: "/users",
          icon: "mdi-account-tie",
          disabled: false,
        },
         {
          label: "Registros",
          path: "/logs",
          icon: "mdi-text-box-search-outline",
          disabled: false,
        },
      ],
    };
  },

  methods: {
    ...mapActions(["setSignOut"]),

    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark", this.$vuetify.theme.dark);
    },

    exit() {
      this.setSignOut();
      this.$router.push("/auth");
    },
  },
};
</script>

<style lang="scss" scoped>
.radius {
  border-radius: 24px 24px 0px 0px;
}
</style>