import axios from "axios";
import store from "@/store";
import router from "@/router";

export const api = axios.create({
  baseURL: "https://backoffice2.loguspay.com/api", //"https://checkout.loguspay.com/api", //https://backoffice.loguspay.com/api
  // baseURL: "http://localhost:4000",
  timeout: 10000,
});

// request
export async function request(method, endpoint, data, headers = {}) {
  return await new Promise((resolve, reject) => {
    try {
      endpoint = endpoint.replaceAll("undefined", "");

      api[method](endpoint, data, { headers: headers })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err.response);
        });
    } catch (err) {
      return reject(err.response);
    }
  });
}

// interceptor
api.interceptors.response.use(
  (res) => responseSuccess(res),
  (err) => responseFailed(err)
);

const responseSuccess = (res) => Promise.resolve(res);

const responseFailed = async (err) => {
  // Unauthorized
  if ([401, 403].includes(err.response.status) || err.response.status >= 500) {
    store.dispatch("setSignOut");

    await router.push({
      path: "/auth",
    });
  }

  return Promise.reject(err);
};
