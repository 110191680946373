import io from 'socket.io-client';

const socket = io('https://backoffice2.loguspay.com');

socket.on("connect", () => {
    console.log("socket connected");
})

socket.on("disconnect", () => {
    console.log("socket disconnected");
})

export default socket