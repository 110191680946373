<template>
  <v-snackbar
    v-model="snackbar"
    style="z-index: 99999"
    :color="colors[type]"
    :timeout="timeout"
    top
    rounded="pill"
  >
    <v-icon left dark>
      {{ icons[type] }}
    </v-icon>

    <span>{{ message }}</span>

    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" icon dark @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      colors: ["success", "error", "info"],
      icons: ["mdi-check", "mdi-alert-circle", "mdi-alert"],
      snackbar: false,
      message: "",
      type: 0,
      timeout: 3000,
    };
  },

  mounted() {
    this.$root.$on("display-alert", (data) => {
      this.message = data.message;
      this.type = data.type || 0;
      this.timeout = data.fixed ? -1 : 3000;
      this.snackbar = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.alert-style {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>